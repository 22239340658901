<template>
	<div id="app">
		<div class="goods">
        	<div>已选条件</div>
			<span class="no-goods" v-if = "!showFiltergoods">暂时没有商品</span>
			<div class="item">
				<li v-for = "(goods,goodsKey) in filterList">{{ goods }}
					<span class="delete-goods"	@click = "deleteFilterGoods(goodsKey)">x</span>
				</li>
			</div>
    	</div>

       <!-- <div class="goods" v-for = "(goods,goodsIndex) in goodsList" :key = "goods.id">
        	<div class="title">{{ goods.title }}:</div>
			<div class="item">
				<div class="type" :class = "{active:typeIndex === goods.index}" v-for = "(type,typeIndex) in goods.typeList" :key = "type"
				@click = "handleclick(typeIndex,goods,type,goodsIndex)"
				>{{ type }}</div>
			</div>
		</div> -->

		<div class="goods" >
        	<div class="title">投放地区:</div>
			<div class="item">
				<div class="type" :class = "{active:index === areaStationList.index}" v-for = "(area,index) in areaStationList" :key = "area"
				@click = "handleclick(index,area.label,0)"
				>{{ area.label }}</div>
			</div>
		</div>

		<div class="goods" >
        	<div class="title">投放位置:</div>
			<div class="item">
				<div class="type" :class = "{active:index === positionList.index}" v-for = "(area,index) in positionList" :key = "area"
				@click = "handleclick(index,area.label,1)"
				>{{ area.label }}</div>
			</div>
		</div>

		<div class="goods" >
        	<div class="title">投放类型:</div>
			<div class="item">
				<div class="type" :class = "{active:index === mediaTypeList.index}" v-for = "(area,index) in mediaTypeList" :key = "area"
				@click = "handleclick(index,area.label,2)"
				>{{ area.label }}</div>
			</div>
		</div>
        
    </div>
</template>

<script>
import { mapGetters } from "vuex";
	// import {Button,Sidebar} from 'vant';
	// import { Button,Sidebar,Grid } from 'vant';
	// import { createApp } from 'vue'
	// //引入需要添加的组件
	// // import Icon from "@/components/Icon.vue";
	// //添加组件
	// createApp().component('Button', Button);
	// createApp().component('Sidebar', Sidebar);
	// createApp().component('Grid', Grid);

    // var count=0;
	export default {
		computed: {...mapGetters(['mediaTypeList','positionList','areaStationList'])},
		data() {
			return {
				filterList:{},
                showFiltergoods:false,
                goodsList: [
					{
						title: '上装',
						typeList: ['全部', '针织衫', '毛呢外套', 'T恤', '羽绒服', '棉衣', '卫衣', '风衣' ],
						id: 1,
					},
					{
						title: '裤装',
						typeList: ['全部', '牛仔裤', '小脚/铅笔裤', '休闲裤' ,'打底裤', '哈伦裤'],
						id: 2,
					},
					{
						title: '裙装',
						typeList: ['全部', '连衣裙', '半身裙', '长袖连衣裙', '中长款连衣裙'],
						id: 3,
					}
				]
            }
		},

		async mounted() {
      		await this.$store.dispatch('operate/areaStationList');
			await this.$store.dispatch('operate/mediaTypeList');
			await this.$store.dispatch('operate/positionList');			
		},
        
		created: function () {
			// 设置初始选中项
			// this.selectOption = this.optionsDatas[0];

		},

		methods: {
			handleclick(index,area,cateIndex){
                if(area === "全部"){
                    return;
                }
                this.showFiltergoods = true;
				switch(cateIndex){
					case 0:
						this.areaStationList.index = index;
						break;
					case 1:
						this.positionList.index = index;
						break;
					case 2:
						this.mediaTypeList.index = index;
						break;
					default:
						break;
				}
                
                // this.filterList.splice(goodsIndex,1,type);
				// console.log(this.filterList,goodsIndex,type)
                // this.$set(this.filterList,goodsIndex,type)
				this.filterList[cateIndex]=area;

            },

			//  handleclick(typeIndex,goods,type,goodsIndex){
			// 	let vm = this;
            //     if(type === "全部"){
            //         return;
            //     }
            //     this.showFiltergoods = true;
            //     goods.index = typeIndex;
            //     // this.filterList.splice(goodsIndex,1,type);
			// 	console.log(this.filterList,goodsIndex,type)
            //     // this.$set(this.filterList,goodsIndex,type)
			// 	this.filterList[goodsIndex]=type;

            // },
                deleteFilterGoods(goodsKey){
                    // this.filterList
                    this.$delete(this.filterList,goodsKey);
                    this.goodsList[goodsKey].index = 0;
                    this.changeShowFilter();
                },
                changeShowFilter(){
                    const filterListStr =JSON.stringfy(this.filterList);
                    this.showFiltergoods=filterListStr !== "{}"
                }
	    }
    }
	// vm.goodsList.forEach(item => vm.$set(item,'index',0));
</script>

<style scoped="scoped">
	.van-sidebar::-webkit-scrollbar {
		display: none;
	}
	.item{
		display:flex;
		flex-direction:row;
	}
	.goods{
		display:flex;
		flex-direction:row;
	}
	.active{
		background-color: cadetblue;
	}
</style>